.App {
  background-color: var(--gray6);
}

.App-screen {
  display: none;
}

/* @media (max-width: 650px) {
  .App-data {
    display: none;
    content: "U need a bigger screen";
  }
  .App-screen {
    display: block;
    width: 100vw;
    height: 100vh;
    margin: 0;
    color: var(--gray0);
    text-align: center;
  }
} */