.header {
    display: flex;
    width: calc(100% - 10rem);
    justify-content: center;
    align-items: center;
    padding: 0 5rem;
    height: max(100vh, 48rem);
}

.header-title {
    max-height: 40rem;
    max-width: 60rem;
}

.small-title {
    color: var(--red-light);
    margin: 0;
    font-size: 32px;
    margin: 0 auto;
}

.header-title h1 {
    font-size: 96px;
    color: var(--gray0);
    margin: 1.75rem auto;
}

.header-title h2 {
    font-size: 56px;
    color: var(--gray1);
    margin: 0 auto;
    margin-bottom: 4.5rem;
}

.small-text {
    font-size: 32px;
    color: var(--gray1);
    margin-bottom: 4.5rem;
}

.btn-primary {
    padding: 20px 30px;
    outline: none;
    border-width: 3px;
    border-color: var(--red-light);
    border-radius: 7px;
    background-color: transparent;
    font-size: 32px;
    color: var(--gray1);
    cursor: pointer;
    transition-duration: 400ms;
}

.btn-primary a {
    text-decoration: none;
    color: var(--gray1);
    font-size: 32px;
    padding: 20px 30px;
    margin: 0;
}

.btn-primary:active {
    outline: none;
    border-style: outset;
}

.btn-primary:hover {
    outline: none;
    background-color: rgb(255, 92, 124, 0.2);
    transform: translateY(-7px);
}

@media (max-width: 769px) {

    .small-title {
        font-size: 28px;
    }

    .header-title h1 {
        margin: 0rem auto;
        font-size: 80px;
    }

    .header-title h2 {
        margin-bottom: 2rem;
        font-size: 48px;
    }

    .small-text {
        margin-bottom: 2rem;
        font-size: 28px;
    }

    .btn-primary a {
        font-size: 28px;
    }

}

@media (max-width: 481px) {

    .header {
        width: calc(100% - 9rem);
        padding: 0 4.5rem;
    }

    .small-title {
        font-size: 24px;
    }

    .header-title h1 {
        margin: 0rem auto;
        font-size: 64px;
        line-height: 72px;
    }

    .header-title h2 {
        margin-bottom: 1rem;
        font-size: 32px;
    }

    .small-text {
        margin-bottom: 1rem;
        font-size: 24px;
    }

    .btn-primary a {
        font-size: 18px;
    }

    .btn-primary {
        padding: 20px 10px;
        font-size: 18px;
    }

}

@media (max-width: 320px) {

    .small-title {
        font-size: 18px;
    }

    .header-title h1 {
        margin: 0rem auto;
        font-size: 40px;
        line-height: 44px;
    }

    .header-title h2 {
        margin-bottom: 0.5rem;
        font-size: 24px;
    }

    .small-text {
        margin-bottom: 2rem;
        font-size: 18px;
    }

    .btn-primary a {
        font-size: 12px;
    }

    .btn-primary {
        padding: 20px 0px;
        font-size: 12px;
    }

}