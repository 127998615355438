.middlepart {
    height: 17rem;
    width: calc(100% - 10rem);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    max-width: 48rem;
    margin: auto;
}

.triangle {
    height: 37px;
    width: 37px;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.circle {
    height: 37px;
    width: 37px;
    border-color: var(--purple-light);
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.square {
    height: 27px;
    width: 27px;
    border-color: var(--green-light);
    border-width: 3px;
    border-style: solid;
    transform: rotate(45deg);
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

@media (max-width: 481px) {
    .middlepart {
        width: calc(100% - 9rem);
        padding: 0 4.5rem;
        height: 10rem;
    }

    .triangle {
        transform: scale(.6);
    }
    .circle {
        transform: scale(.6);
    }
    .square {
        transform: scale(.6);
    }
}