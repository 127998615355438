:root {
  --red: #ff3860;
  --red-dark: #ff1443;
  --red-light: #ff5c7c;
  --blue: #498afb;
  --blue-dark: #2674fa;
  --blue-light: #6ca0fc;
  --orange: #fa8142;
  --orange-dark: #f96a1f;
  --orange-light: #fb9865;
  --green: #09c372;
  --green-dark: #07a15e;
  --green-light: #0be586;
  --purple: #9166cc;
  --purple-dark: #7d4bc3;
  --purple-light: #a481d5;
  --pink: #ff4088;
  --pink-dark: #ff1c72;
  --pink-light: #ff649e;
  --gray0: #f8f8f8;
  --gray1: #dbe1e8;
  --gray2: #b2becd;
  --gray3: #6c7983;
  --gray4: #454e56;
  --gray5: #2a2e35;
  --gray6: #12181b;
}

html {
  scrollbar-color: var(--gray4) var(--gray6);
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}