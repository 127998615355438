.main {
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow: hidden;
    position: fixed;
    z-index: 10;
    pointer-events: none;
}

.social {
    position: absolute;
    left: 6rem;
    bottom: 0;
    display: flex;
    flex-direction: column;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    /* pointer-events: none; */
}

.social::after {
    content: "";
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    width: 3px;
    height: 22rem;
    background-color: var(--red-light);
}

.social a {
    pointer-events: all;
}

.social a img {
    margin-top: 1.75rem;
    fill: var(--red-light);
    cursor: pointer;
    pointer-events: none;
}

.email {
    position: absolute;
    right: 6rem;
    bottom: 0;
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.email::after {
    content: "";
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    width: 3px;
    height: 11rem;
    background-color: var(--red-light);
}

.email a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
}

.email a p {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: var(--red-light);
    font-size: 24;
    margin: 0;
}

@media (max-width: 1370px) {

    .social {
        left: 2rem;
    }

    .email {
        right: 2rem;
    }

}

@media (max-width: 1024px) {

    .social {
        left: 1rem;
    }

    .email {
        right: 1rem;
    }

}