.projects {
    margin: 0 auto;
    padding: 0 5rem;
    max-width: 80rem;
}

.projects-container {
    display: grid;
    row-gap: 50px;
    margin: 8rem 0;
    grid-template-columns: auto auto;
    justify-content: space-around;
}

.projects-card {
    position: relative;
    width: 24rem;
    border: 3px solid var(--red-light);
    border-radius: 10px;
    padding: 2.5rem 3rem;
}

.projects-card img {
    width: 6rem;
    position: absolute;
    opacity: 1;
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    top: 50%;
    transform: translateY(-50%);
    right: 3rem;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.projects-card h6 {
    font-size: 32px;
    margin: 0;
    color: var(--gray0)
}

.projects-card p {
    width: 60%;
    color: var(--gray2)
}

.projects-card a {
    font-size: 18px;
    color: var(--red-light);
    font-weight: bold;
    text-decoration: none;
}

@media (max-width: 1370px) {

    .projects-container {
        grid-template-columns: auto;
    }

    .projects-card {
        width: 32rem;
        min-width: 24rem;
    }

    .projects {
        padding: 0 8rem;
    }

}

@media (max-width: 1000px) {

    .projects {
        padding: 0 4rem;
    }

}

@media (max-width: 769px) {

    .projects-card {
        position: relative;
        width: 16rem;
        min-width: 14rem;
        border: 3px solid var(--red-light);
        border-radius: 10px;
        padding: 2.5rem 3rem;
    }

}

@media (max-width: 481px) {

    .projects-card {
        position: relative;
        width: calc(100% - 8rem);
        min-width: calc(100% - 8rem);
        border: 3px solid var(--red-light);
        border-radius: 10px;
        padding: 2.5rem 3rem;
        margin: 0 1rem;
    }

    .projects-card img {
        display: none;
    }

    .projects-card p {
        width: 100%;
    }

}