.footer {
    position: relative;
    margin: 0 auto;
    padding: 0 0;
    max-width: 80rem;
}

.footer h5 {
    color: var(--red-light);
    text-align: center;
    margin: 0;
    padding-bottom: 2rem;
}