.contact {
    position: relative;
    margin: 0 auto;
    padding: 0 12rem;
    max-width: 80rem;
}

.contact-container {
    text-align: center;
    margin: 8rem 0;
}

.contact-container h1 {
    font-size: 64px;
    margin: 0;
    color: var(--gray0);
}

.contact-container h5 a,
.contact-container h5  {
    font-size: 18px;
    margin: 0;
    color: var(--red-light);
    text-decoration: none;
}

.contact-container p {
    font-size: 24px;
    color: var(--gray1);
    line-height: 40px;
    margin: 4rem 0;
}

@media (max-width: 1370px) {

    .contact {
        padding: 0 8rem;
    }

}

@media (max-width: 1000px) {

    .contact {
        padding: 0 4rem;
    }

}

@media (max-width: 481px) {
    .contact-container {
        text-align: center;
        margin: 8rem 0;
        margin-bottom: 4rem;
    }
}