.about {
    position: relative;
    margin: 0 auto;
    padding: 0 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80rem;
}

.about-text {
    display: block;
    width: 63%;
}

.about-image {
    display: block;
    position: relative;
    width: 37%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-image img {
    width: 20rem;
    border-radius: 20px;
    z-index: 3;
}

.about-image div {
    z-index: 1;
}

.about-image div {
    content: "";
    position: absolute;
    border-width: 3px;
    width: 20rem;
    height: 100%;
    left: 60%;
    transform: translate(-50%, 30px);
    border-style: solid;
    border-radius: 20px;
    border-color: var(--red-light);
}

.balise {
    color: var(--red-light);
    font-size: 40px;
}

.unselectable {
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.about-text p {
    font-size: 32px;
    color: var(--gray1);
    margin-left: 5rem;
    margin-right: 3rem;
    margin-top: 8rem;
    margin-bottom: 8rem;
}

@media (max-width: 1370px) {

    .about-text p {
        font-size: 24px;
    }

    .about {
        padding: 0 8rem;
    }

}

@media (max-width: 1000px) {

    .about-image {
        display: none;
    }

    .about-text {
        display: block;
        width: 100%;
    }

    .about {
        padding: 0 4rem;
    }

}

@media (max-width: 769px) {
    .about-text p {
        margin-left: 2rem;
        margin-right: 2rem;
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
}

@media (max-width: 481px) {
    .about-text p {
        font-size: 22px;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 6rem;
        margin-bottom: 4rem;
    }
    .balise {
        font-size: 32px;
    }
}

@media (max-width: 320px) {
    .about-text p {
        font-size: 14px;
        margin-top: 4rem;
    }
}