.experience {
    margin: 0 auto;
    padding: 0 5rem;
    width: min(80rem, calc(100% - 10rem));
}

.exp-container {
    display: flex;
    justify-content: center;
    margin: 8rem 0;
    /* width: calc(100% - 10rem); */
}

.exp-selector {
    display: block;
    width: 35%;
    border-right: 3px solid var(--red-light);
    color: var(--gray2);
    font-size: 24px;
}

.exp-text {
    display: block;
    width: 65%;
    padding-left: 6rem;
}

.exp-selector div {
    text-align: right;
    padding: 2rem 3rem;
    cursor: pointer;
}

.exp-selected {
    color: var(--gray0);
}

.exp-selector div h4 {
    padding: 0;
    margin: 0;
}

.exp-text h2 {
    font-size: 48px;
    color: var(--gray1);
    margin: 0;
}

.exp-text h5 {
    font-size: 20px;
    color: var(--red-light);
    margin: 0;
}

.exp-text p {
    font-size: 24px;
    color: var(--gray1);
    margin-top: 3rem;
}

@media (max-width: 1370px) {

    .exp-selector {
        font-size: 20px;
        width: 40%;
    }

    .experience {
        padding: 0 8rem;
        width: min(80rem, calc(100% - 16rem));
    }

}

@media (max-width: 1000px) {

    .experience {
        padding: 0 4rem;
        width: min(80rem, calc(100% - 8rem));
    }

}

@media (max-width: 769px) {
    .exp-container {
        flex-direction: column;
        margin: 4rem 0;
    }

    .exp-selector {
        display: flex;
        flex-direction: column;
        width: 100%;
        color: var(--gray2);
        font-size: 24px;
        border: none;
        overflow-x: hidden;
        justify-content: space-evenly;
        margin-bottom: 2rem;
    }

    .exp-selector div {
        text-align: center;
        padding: 1.5rem 0;
        cursor: pointer;
    }
    
    .exp-text {
        display: block;
        width: calc(100% - 4rem);
        padding-left: 0rem;
        margin: 0 2rem;
    }
}

@media (max-width: 481px) {

    .exp-text h2 {
        font-size: 36px;
    }
    
    .exp-text h5 {
        font-size: 16px;
    }
    
    .exp-text p {
        font-size: 20px;
        margin-top: 2rem;
    }

}